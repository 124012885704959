import { ReactComponent as Teams } from "../assets/svgIcons/lsq_teams_disabled.svg";
import { ReactComponent as Add } from "../assets/svgIcons/add.svg";
import { ReactComponent as TeamsActive } from "../assets/svgIcons/lsq_teams_active.svg";
import { ReactComponent as LsqUnassign } from "../assets/svgIcons/lsq_unassign.svg";
import { ReactComponent as LsqUnpublish } from "../assets/svgIcons/lsq_unpublish.svg";
import { ReactComponent as LsqClone } from "../assets/svgIcons/lsq_clone.svg";
import { ReactComponent as LsqDelete } from "../assets/svgIcons/delete_filled.svg";
import { ReactComponent as LsqPublish } from "../assets/svgIcons/lsq_ok.svg";
import { ReactComponent as Help } from "../assets/svgIcons/lsq_help.svg";
import { ReactComponent as HelpFilled } from "../assets/svgIcons/lsq_help_filled.svg";
import { ReactComponent as Homepages } from "../assets/svgIcons/homepages.svg";
import { ReactComponent as HomepagesFilled } from "../assets/svgIcons/homepages_filled.svg";
import { ReactComponent as MenuBuilder } from "../assets/svgIcons/lsq_menu.svg";
import { ReactComponent as MenuBuilderFilled } from "../assets/svgIcons/lsq_menu_filled.svg";
import { ReactComponent as ThemeFilled } from "../assets/svgIcons/lsq_themes_filled.svg";
import { ReactComponent as Theme } from "../assets/svgIcons/lsq_themes.svg";
import { ReactComponent as LeadControl } from "../assets/svgIcons/lead_control.svg";
import { ReactComponent as LeadControlFilled } from "../assets/svgIcons/lead_control_filled.svg";
import { ROUTES_PATH, casaConstants, homepageConstants, menuBuilderConstants, themeConstants } from "../constants/globalConstant";
import { teamsDashboardsMappingWithTeamId } from "./reducersUtils";

export const sideMenuOptions = [
  {
    path: ROUTES_PATH.home,
    displayIcon: <Teams />,
    activeIcon: <TeamsActive/>,
    tooltip : homepageConstants.TEAM_ASSIGNMENT,
    view: homepageConstants.TEAM_ASSIGNMENT_VIEW,
    type: homepageConstants.INTERNAL,
    analyticsMessage: "teams_view_click",
    "data-testid": "team-assignment-view-tab",
  },
  {
    path: ROUTES_PATH.homepages,
    displayIcon: <Homepages />,
    activeIcon: <HomepagesFilled />,
    tooltip : homepageConstants.HOMEPAGES,
    view: homepageConstants.HOMEPAGES_VIEW,
    type: homepageConstants.INTERNAL,
    associatedPaths: [
      ROUTES_PATH.homebuilder,
      ROUTES_PATH.templates,
    ],
    analyticsMessage: "hompeages_view_click",
    "data-testid": "home-view-tab",
  },
  {
    path: ROUTES_PATH.menus,
    displayIcon: <MenuBuilder />,
    activeIcon: <MenuBuilderFilled />,
    tooltip : homepageConstants.MENUS,
    view: homepageConstants.MENU_BUILDER_VIEW,
    type: homepageConstants.INTERNAL,
    associatedPaths: [
      ROUTES_PATH.menubuilder,
    ],
    analyticsMessage: "menu_view_click",
    "data-testid": "menu-builder-view-tab",
  },
  {
    path: ROUTES_PATH.themebuilder,
    displayIcon: <Theme />,
    activeIcon: <ThemeFilled/>,
    tooltip : homepageConstants.THEMING,
    view: homepageConstants.THEME_BUILDER_VIEW,
    type: homepageConstants.INTERNAL,
    analyticsMessage: "theme_view_click",
    "data-testid": "theme-view-tab",
  },
  {
    path: ROUTES_PATH.leadControls,
    displayIcon: <LeadControl />,
    activeIcon: <LeadControlFilled/>,
    tooltip : homepageConstants.LEAD_CONTROLS,
    view: homepageConstants.LEAD_CONTROLS_VIEW,
    type: homepageConstants.INTERNAL,
    analyticsMessage: "lead_control_view_click",
    "data-testid": "lead_control_view-tab",
  }
];

export const menuConfiguratorTabs = [
  {
    key : menuBuilderConstants.BOTTOM_NAVIGATION,
    value : menuBuilderConstants.BOTTOM_NAVIGATION,
    id : "bottom-navigation",
    "data-testid" : "bottom-navigation",
  },
  {
    key : menuBuilderConstants.SIDE_NAVIGATION,
    value :  menuBuilderConstants.SIDE_NAVIGATION,
    id : "side-navigation",
    "data-testid" : "side-navigation",
  }
]

export const themeConfiguratorTabs = [
  {
    key : themeConstants.CONFIGURE_THEMING,
    value : themeConstants.CONFIGURE,
    id : "Configure-Theming",
    "data-testid" : "configure-theming",
  }
]

export const sideMenuBottomOptions = [
  {
    displayIcon: <Add />,
    activeIcon: <Add />,
    tooltip : homepageConstants.CREATE_NEW,
    view: homepageConstants.CREATE_NEW_VIEW,
    type: homepageConstants.INTERNAL,
    "data-testid": "create-new-tab",
    className: "coach-side-bar-create-new"
  },
  {
    displayIcon: <Help />,
    activeIcon: <HelpFilled />,
    view: homepageConstants.HELP_DESK_VIEW,
    tooltip : homepageConstants.HELP_DESK,
    onClick: () => {
      window.open("https://help.leadsquared.com/mobile-home-builder/", "_blank");
    },
    type: homepageConstants.EXTERNAL,
    "data-testid": "help-desk",
  }
]

export const createNewMenuItems = [
  {
    id: casaConstants.CREATE_NEW_HOMEPAGE,
    label: casaConstants.CREATE_NEW_HOMEPAGE,
    "data-testid": "create-new-homepage",
  },
  {
    id: casaConstants.CREATE_NEW_MENU,
    label: casaConstants.CREATE_NEW_MENU,
    "data-testid": "create-new-menu",
  },
];

export const isLastHomepage = (selectedHomepageId, homepageId) => {
  const routedHomepageId = +homepageId;
  return selectedHomepageId == routedHomepageId;
};

export const teamsViewActions = (status) => {
  return [
    {
      displayIcon:
          status === casaConstants.PUBLISHED 
          ? <LsqUnpublish /> 
          : <LsqPublish style = {{fill : "#efefef",transform : "scale(1.3)"}}/>,
      name:
        status === casaConstants.PUBLISHED
          ? casaConstants.UNPUBLISH
          : casaConstants.PUBLISH,
      backgrounColor: "#0A1F43",
      "data-testid": "unPublish-action",
    },
    {
      displayIcon: <LsqUnassign />,
      name: casaConstants.UNASSIGN,
      backgrounColor: "#E75454",
      "data-testid": "unAssign-action",
    },
  ];
};
export const dashboardViewActions = (selectedHomepageId, homepageId) => {
  const result = isLastHomepage(selectedHomepageId, homepageId);
  let menuItems = [
    {
      displayIcon: <LsqClone />,
      name: casaConstants.CLONE,
      backgrounColor: "#0A1F43",
      "data-testid": "clone-action",
    },
  ];
  if (result === false)
    menuItems.push({
      displayIcon: <LsqDelete />,
      name: casaConstants.DELETE,
      backgrounColor: "#E75454",
      "data-testid": "unAssign-action",
    });

  return menuItems;
};

export const getUpdatedTeamDashboards = (data , teamDashboards) => {
  const _teamDashboards = {...teamDashboards}
  const updatedTeamHomepagesByTeamId = teamsDashboardsMappingWithTeamId(teamDashboards.teamsDashboards, data.teamsDashboards);
  _teamDashboards.teamsDashboards = Object.values(updatedTeamHomepagesByTeamId)
  if(data.defaultDashboards){
    _teamDashboards.defaultDashboards = data.defaultDashboards
  }
  return _teamDashboards
}

export const getRecentlyModifiedDashboard = (dashboards) => {
 if(dashboards.length != 0){
  return dashboards.reduce(function(prev, current) {
    return new Date(current.updatedAt) > new Date(prev.updatedAt) ? current : prev;
  });
}
}
export const searchWidgets = (widgetList, searchText) => {
  if(searchText.trim() === "") return widgetList;
  return widgetList?.filter(({title}) => title.toLowerCase().includes(searchText.trim().toLowerCase()))
}

export const homepageFilterItems = [
  {
    value: casaConstants.ALL,
    key: casaConstants.ALL,
    id: "homepages-all",
    "data-testid": "all-homepages",
  },
  {
    value: casaConstants.PUBLISHED,
    key: casaConstants.PUBLISHED,
    id: "homepages-published",
    "data-testid": "published-homepages",
  },
  {
    value: casaConstants.UNPUBLISHED,
    key: casaConstants.UNPUBLISHED,
    id: "homepages-unpublished",
    "data-testid": "unpublished-homepages",
  },
  {
    value: casaConstants.DRAFT,
    key: casaConstants.DRAFT,
    id: "homepages-draft",
    "data-testid": "draft-homepages",
  },
];